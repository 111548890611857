.gls__tutorial-holder {
  align-items: center;
  box-sizing : border-box;
  color      : #101920;
  gap        : 1rem;
  height     : 100%;
  max-width  : 100%;
  overflow   : hidden;
  width      : 100%;
  z-index    : 0;
}

.gls__tutorial-card {
  background   : white;
  border-radius: .5rem;
  box-shadow   : rgba(50, 50, 93, 0.25) 0px 10px 20px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 6px -3px;
  box-sizing: border-box;
  color     : #101920;
  margin    : 1.53rem;
  overflow  : hidden;
  position  : relative;
  z-index   : 1;
}

.gls__tutorial-card > h1 {
  bottom     : -3rem;
  color      : rgb(208 209 210);
  font-size  : 16em;
  line-height: 1;
  margin     : 0;
  position   : absolute;
  right      : -1rem;
  z-index    : -1;
}

.gls__tutorial-card img {
  max-width : 100%;
  object-fit: scale-down;
  object-fit: cover;
  width     : 100%;
}

.gls__tutorial-card h2 {
  font-size  : 1.8em;
  line-height: 1.1;
  margin     : 0;
  text-align : center;
  text-wrap  : balance;
}

.gls__tutorial-card--info {
  padding: 1rem;
}

.gls__tutorial-page {
  align-items    : center;
  display        : flex;
  flex-direction : column;
  font-size      : clamp(12px, .8vw, 38px);
  justify-content: center;
  margin-top     : 5rem;
  max-height     : 100%;
  max-width      : 100%;
  min-height     : 100%;
  min-width      : 100%;
}

.gls__tutorial-overview {
  background   : white;
  border-radius: 0.5rem;
  box-shadow   : rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-sizing   : border-box;
  color        : #101920;
  font-size    : clamp(12px, 0.8vw, 32px);
  margin       : 1.5rem;
  overflow     : hidden;
}

.gls__tutorial-overview > div {
  border-radius : 0.5rem;
  box-sizing    : border-box;
  display       : flex;
  flex-direction: column;
  margin        : 1rem;
  padding       : 1rem;
}

.gls__tutorial-overview > img {
  max-width : 100%;
  object-fit: cover;
  width     : 100%;
}

.gls__tutorial-overview h1 {
  font-size: 2.62em;
  margin   : 0 0 0 0;
}

.gls__tutorial-overview h2 {
  font-size  : 1.62em;
  font-weight: 500;
  line-height: 1;
  margin     : 0.5rem 0 1rem;
}

.gls__tutorial-overview h3 {
  box-sizing : border-box;
  font-size  : 1em;
  font-weight: 300;
  line-height: 1.2;
  margin     : 0.5rem 0;
}

.gls__tutorial-overview h4 {
  box-sizing : border-box;
  font-size  : .8em;
  font-weight: 300;
  line-height: 1.2;
  margin     : 0.5rem 0;
}

.gls__tutorial-card--info * {
  text-shadow: 0px 0px 2px white, 0px 0px 2px white;
}

.gls__tutorial-card--image-group {
  align-items    : center;
  box-sizing     : border-box;
  display        : flex;
  height         : auto;
  justify-content: center;
  margin         : 0px;
  padding        : 0;
  position       : relative;
  width          : 100%;
}

.gls__tutorial-card--image-group p {
  background: linear-gradient(270deg, rgb(16 25 32 / 25%) 50%, transparent 85%);
  bottom    : 0;
  box-sizing: border-box;
  color     : white;
  margin    : 0;
  padding   : .25rem .5rem .25rem 4.5rem;
  position  : absolute;
  right     : 0;
  text-align: right;
}

.gls__tutorial-card--coming-soon {
  align-items    : center;
  backdrop-filter: blur(2px) grayscale(50%);
  background     : rgba(255, 255, 255, 0.5);
  display        : none;
  height         : 100%;
  justify-content: center;
  left           : 0;
  position       : absolute;
  top            : 0;
  width          : 100%;
  z-index        : 1;
}

a.gls__tutorial-card.soon .gls__tutorial-card--coming-soon {
  display       : flex;
  pointer-events: none;
}

a.gls__tutorial-card.soon {
  pointer-events: none;
}

a.gls__tutorial-card.soon .gls__tutorial-card--image-group p {
  display: none;
}

.gls__tutorial-card--coming-soon h1 {
  color         : #63666b;
  text-shadow   : 0 0 10px rgba(255, 255, 255, 1);
  box-sizing    : border-box;
  font-size     : 2em;
  line-height   : .8;
  padding       : 1.5rem;
  text-align    : center;
  text-transform: uppercase;
  width         : 90%;
  border-radius : 1rem;
}

.gls__tutorial-card--info ul {
  font-size  : 1.4em;
  line-height: 1.2;
}

ul.gls__tutorial-icons {
  align-items    : center;
  box-sizing     : border-box;
  display        : flex;
  gap            : 2em;
  justify-content: center;
  list-style     : none;
  margin         : .5rem auto;
  max-width      : 100%;
  padding        : 0;
  width          : 100%;
}

ul.gls__tutorial-icons li {
  display: block;
  flex   : 2;
  margin : 0;
  padding: 0;
}

ul.gls__tutorial-icons img {
  object-fit: contain;
  width     : 80%;
}

.gls__tutorial-overview button {
  background    : #63666b;
  border        : none;
  border-radius : 1em;
  box-shadow    : rgba(50, 50, 93, 0.25) 0px 10px 20px -2px, rgba(0, 0, 0, 0.3) 0px 3px 6px -3px;
  color         : white;
  cursor        : pointer;
  font-size     : 1em;
  font-weight   : 300;
  letter-spacing: 2px;
  outline       : none;
  padding       : 1em 2em;
  text-transform: uppercase;
}

.gls__tutorial-overview a {
  align-self: center;
  margin    : 1rem 0 0 0;
}