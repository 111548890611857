
.gls__contact form {
  display       : flex;
  flex-direction: column;
  gap           : 1rem;
  padding       : 2rem 2rem 0 2rem;
}

.gls__contact-group {
  display: flex;
}

.gls__contact-group:after {
  content      : " ";
  background   : #515D61;
  width        : calc(100% - 6rem);
  height       : calc(100% - 2rem);
  position     : absolute;
  bottom       : 0;
  left         : 4rem;
  border-radius: 1rem;
  z-index      : -1;
  box-sizing   : border-box;
}

.gls__contact {
  position: relative;
}

.gls__contact-group__sub.gls__contact-group__sub--image {
  display        : flex;
  justify-content: flex-end;
}

.gls__contact-group__sub.gls__contact-group__sub--form {
  color        : #101920;
  padding      : 2rem 4rem;
  background   : white;
  border       : 10px solid #a2aaad;
  border-radius: 1.5rem;
  margin-top   : 1rem;
  box-sizing   : border-box;
  position     : relative;
}

.gls__contact-group {
  display    : flex;
  align-items: center;
}

.gls__contact-group__sub.gls__contact-group__sub--image {
  margin-left: -4rem;
}

.gls__form-group {
  display       : flex;
  flex-direction: column;
  position      : relative;
}

.gls__contact-group__sub.gls__contact-group__sub--form h1 {
  margin: 0;
  color : #515d61;
}

.gls__contact-group__sub.gls__contact-group__sub--form p {
  margin: 0;
}

.gls__contact-group__sub.gls__contact-group__sub--form h1 span {
  color: #101920;
}

.gls__form-group button {
  color         : white;
  background    : #515d61;
  box-sizing    : border-box;
  outline       : none;
  border        : none;
  padding       : 1rem;
  text-transform: uppercase;
  text-align    : left;
  border-radius : 0.5rem;
  cursor        : pointer;
}

.gls__form-group input,
.gls__form-group textarea {
  background   : #e5e7e8;
  color        : #101920;
  border-radius: .5rem;
  outline      : none;
  padding      : .5rem;
  border       : none;
}

.gls__form-group label {
  font-size  : .8rem;
  font-weight: 300;
}

.gls__portfolio-body .gls__contact-group__sub h2 {
  font-weight: 500;
}

.gls__form-group__form {
  opacity       : 0;
  transition    : opacity .5s ease;
  pointer-events: none;
}

.gls__form-group__form.open {
  opacity       : 1;
  pointer-events: auto;
}

.gls__form-group__message {
  position       : absolute;
  top            : 0;
  left           : 0;
  opacity        : 0;
  width          : 100%;
  height         : 100%;
  display        : flex;
  align-items    : center;
  justify-content: center;
  transition     : opacity .5s ease;
  pointer-events : none;
}

.gls__form-group__message.open {
  opacity: 1;
  pointer-events: auto;
}

.gls__contact-group__sub.gls__contact-group__sub--image {
  align-self: flex-end;
  z-index: 2;
}