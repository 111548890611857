    /* General */

html {
  color      : #2B2E30;
  font-family: Poppins, sans-serif;
  text-wrap  : balance;
}

/* .gls__portfolio-body {
  width    : 100vw;
  max-width: calc(100% + 1rem);
} */

.gls__flex {
  display: flex;
  box-sizing: border-box;
  gap: 1rem;
}

h1 {
  line-height: 1.1;
}

.gls__portfolio-body a,
.gls__portfolio-body a:visited,
.gls__portfolio-body a:active,
.gls__portfolio-body a:hover,
.gls__portfolio-body a:-webkit-any-link {
  color      : white;
  cursor     : pointer;
  font-weight: 500;
}

    /* Landing */

.gls__section {
  box-sizing: border-box;
  height    : 100%;
  max-height: 100vh;
  max-width : 100%;
  position  : relative;
  width     : 100vw;
  z-index   : 0;
}

.gls__section.gls__section--landing {
  align-items    : center;
  background     : #819f87;
  color          : #FFF;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  max-height     : 100dvh;
  height     : 100vh;
}

.gls__section.gls__section--landing .gls__section__sub.gls__section__sub--intro > div {
  display  : flex;
  gap      : 3rem;
  max-width: 800px;
  width    : 60%;}

.gls__section.gls__section--landing .gls__section__sub.gls__section__sub--intro {
  align-items    : center;
  display        : flex;
  justify-content: center;
  width          : 100%;
}

.gls__absolute-content {
  align-items    : center;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  position       : fixed;
  width          : 100%;
}

    /* Scroll Down */

.gls__scroll-down {
  align-items    : center;
  bottom         : 0;
  box-sizing     : border-box;
  display        : flex;
  font-size      : 2rem;
  justify-content: center;
  left           : 0;
  max-width      : 100%;
  padding        : 2rem;
  position       : absolute;
  width          : 100%;
}

.gls__scroll-down i {
  animation: jello-horizontal 5s 5s infinite both;
}

p .gls__paragraph-under {
  background-image   : linear-gradient(0, white, transparent);
  background-size    : 0% 2px;
  background-repeat  : no-repeat;
  background-position: left bottom;
  transition         : .5s ease;
  cursor             : pointer;
}

p:hover .gls__paragraph-under {
  background-size: 100% 2px;
}

@keyframes jello-horizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }

  5% {
    transform: scale3d(1.25, 0.75, 1);
  }

  10% {
    transform: scale3d(0.75, 1.25, 1);
  }

  15% {
    transform: scale3d(1.15, 0.85, 1);
  }

  17% {
    transform: scale3d(0.95, 1.05, 1);
  }

  20% {
    transform: scale3d(1.05, 0.95, 1);
  }

  25% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

  /* Gallery */

.gls__section.gls__section--work {
  background: #688197;
  color     : #FFF;
  max-width : 100%;
  min-height: 100vh;
  width     : 100vw;
}

.gls__section.gls__section--work .gls__section__sub {
  align-items    : center;
  box-sizing     : border-box;
  display        : flex;
  flex-direction : column;
  height         : 100%;
  justify-content: center;
  left           : 0;
  overflow       : hidden;
  position       : absolute;
  top            : 0;
  width          : 100%;
  z-index        : 0;
}

  /* Contact */

.gls__section.gls__section--contact {
  align-items    : center;
  background     : #a2aaad;
  color          : #FFF;
  display        : flex;
  height         : 100vh;
  justify-content: center;
}

  /* General Interface */

.gls__text-button {
  background : none;
  border     : none;
  color      : white;
  cursor     : pointer;
  font-size  : 2rem;
  outline    : none;
  padding    : .5rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  transition : .25s ease;
}

span.swiper-pagination-bullet {
  background: white;
}

/* Media Queries */

/* Step 1: 4K Screens */
/* @media only screen and (min-width: 3841px) {
  html {
    font-size: 18px;
  }
} */

/* Step 2: High-Resolution Screens */
/* @media only screen and (min-width: 2561px) and (max-width: 3840px) {
  html {
    font-size: 16px;
  }
} */

/* Step 3: Full HD Screens */
/* @media only screen and (min-width: 1921px) and (max-width: 2560px) {
  html {
    font-size: 14px;
  }
} */

/* Step 4: Standard Screens */
/* @media only screen and (max-width: 1920px) {
  html {
    font-size: 16px;
  }
} */




/* Step 4: Standard Screens */
/* @media only screen and (max-width: 1550px) {
  html {
    font-size: 12px;
  }
} */

html {
  font-size: clamp(12px, 1vw, 22px);
  font-size: clamp(14px, 0.6vw, 22px);
}



  /* For mobile devices */
@media only screen and (max-width: 767px) {

  .gls__contact-group {
      flex-direction: column-reverse;
      max-width     : 100%;
      max-height    : 100%;
      display       : block;
      min-height    : 100vh;
  }
  
  .gls__contact-group__sub.gls__contact-group__sub--image {
      max-height: 100%;
      overflow  : hidden;
  }
  
  .gls__contact-group__sub.gls__contact-group__sub--image img {
      object-fit     : scale-down;
      object-position: right;
  }
  
  .gls__contact-group__sub.gls__contact-group__sub--image {
      margin    : 0;
      box-sizing: border-box;
      flex      : unset;
      max-height: 30vh;
      position  : absolute;
      bottom    : 0;
      right     : 0;
  }
  
  .gls__contact {
      max-height : 100%;
      padding-top: 80px;
      min-height: 100vh;
      box-sizing : border-box;
  }
  
  .gls__contact-group:after {
      display: none;
  }
  
  .gls__contact-group__sub.gls__contact-group__sub--form {
      box-sizing: border-box;
      max-width : calc(100% - 2rem);
      margin    : 1rem;
      padding   : 1rem;
  }
  
  .gls__contact form {
      padding: 1rem;
  }

  .gls__section.gls__section--landing .gls__section__sub.gls__section__sub--intro > div {
    flex-direction: column;
    width         : 80%;
    text-align    : center;
    gap           : 0rem;
    margin        : 0 0 .5rem 0;
  }

  .gls__scroll-anim.gls__big-name {
      max-width: 100vw;
  }

  .gls__scroll-anim.gls__big-name img {
      max-width      : 100%;
      object-fit     : scale-down;
      object-position: bottom;
  }

  .gls__section__sub.gls__section__sub--intro h1 {
      font-size: 1.4rem;
  }

  .gls__section__sub.gls__section__sub--intro p {
      font-size: 0.8rem;
  }

  .gls__gallery-thumb-holder {
    max-height: 100%;
  }

  .gls__gallery {
      padding: 60px 0 20px 0;
  }

  .gls__gallery-body {
    min-width : 100%;
    z-index   : 1;
    transition: .45s ease;
  }

  .gls__gallery .gls__project-panel.gls__project-panel--bottom {
      padding-bottom: 0;
  }

  .gls__gallery .gls__project-panel.gls__project-panel--bottom,
  .gls__gallery .gls__project-panel.gls__project-panel--top {
      border-radius: 0;
  }

  .gls__gallery .gls__bullet-points {
    justify-content: center;
    gap            : .75rem;
  }
}









*::-webkit-scrollbar {
  width: .5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.4);
  border-radius: 10rem;
}

*::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

*::-webkit-scrollbar-track-piece:end {
  background: transparent;
}











/* 
.gls__scroll-anim.gls__gears {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 1;
}

.gls__scroll-anim.gls__gears img {
  min-height: 100%;
  object-fit: contain;
  transform: scaleY(-1);
} */




/* Scroll Bars*/

/* body {
  overflow-y: overlay;
  box-sizing: border-box;
  width: 100vw;  
  scrollbar-gutter: stable;

}

*::-webkit-scrollbar {
  display: block;
  width: 1rem;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background-color: #00000000 !important;
}

*::-webkit-scrollbar-track-piece {
  background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
  background-color: #00000040;
  border: 1px solid #ffffff40;
  border-radius: 2rem;
} */










/* *::-webkit-scrollbar {
  width: .5rem;
}

*::-webkit-scrollbar-track {
  border-radius: 1rem;
  background-color: orange;
  border: none;
  width: .5rem;

}

*::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #ff0000;
  width: .5rem;
} */








.gls__loading-screen {
background: #819f87;
position: absolute;
z-index: 1001;
top: 0;
left: 0;
width: 100%;
height: 100%;
max-width: 100%;
max-height: 100%;
}

.gls__three-holder {
  position: relative;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 800px;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-self: center;
  aspect-ratio: 1.72;
  background: #819f87;
}

.gls__three-holder canvas {

  width: 100%;
  height:  100%;;
  max-width:  100%;
  max-height: 100%;
  background: #819f87;
}


  /* Tutorials */

  .gls__section.gls__section--tutorials {
    background: #63666b;
    color     : #FFF;
    max-width : 100%;
    min-height: 100vh;
    max-height: unset;
    width     : 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }