dialog {
  max-height: 95%;
  max-width : 95%;
  padding   : 1rem;
}

dialog button {
  z-index: 10;
}

dialog::backdrop {
  backdrop-filter: blur(0px);
  background     : rgba(0, 0, 0, 0);
}

dialog .gls__modal-close {
  position: absolute;
  right   : 0;
  top     : 0;
}

.gls__modal-content {
  max-width: 100%;
}

.dialog-entering,
.dialog-exiting,
.dialog-exited {
  opacity  : 0;
  transform: scale(0.9);
}

dialog.gls__dialog-modal.dialog-enter-done,
dialog.gls__dialog-modal.dialog-enter.dialog-enter-active {
  opacity: 1;
}

.gls__dialog-modal {
  background   : none;
  border       : none;
  border-radius: 0.5rem;
  max-width    : 95vmin;
  opacity      : 0;
  overflow     : hidden;
  padding      : 0;
  transition   : opacity 500ms ease-in-out;
}

dialog.gls__dialog-modal.dialog-enter-done::backdrop,
dialog.gls__dialog-modal.dialog-enter.dialog-enter-active::backdrop {
  backdrop-filter: blur(5px);
  background     : rgba(0, 0, 0, 0.75);
  opacity        : 1;
}

dialog::backdrop {
  backdrop-filter: blur(0);
  background     : rgba(0, 0, 0, 0);
  opacity        : 0;
  transition     : 300ms ease-in-out;
}

.gls__modal-content video {
  max-height: 100%;
  max-width : 100%;
}