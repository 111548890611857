    /* Navigation */

nav {
  display        : flex;
  justify-content: center;
  left           : 0;
  position       : fixed;
  top            : 0;
  width          : 100%;
}

nav ul {
  box-sizing     : border-box;
  color          : white;
  display        : flex;
  font-weight    : 500;
  gap            : 1rem;
  justify-content: center;
  list-style     : none;
  margin         : 1rem;
  padding        : 0.25rem;
  position       : relative;
  background     : transparent;
  transition     : .25s ease;
  border-radius  : 0.5rem;
}

/* nav.gls__nav.my-work ul {
  background: #688197;
} */

nav li {
  flex       : 2;
  white-space: nowrap;
  width      : 100%;
}

nav a {
  color          : inherit;
  text-decoration: none;
}

.gls__nav__underline {
  background: white;
  bottom    : 0;
  height    : 2px;
  left      : 0;
  position  : absolute;
  transition: left 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
              width 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
              .5s ease;
}

.gls__nav__underline.gls__nav__underline--active {
  background: white;
}

.gls__nav__underline.gls__nav__underline--hover {
  background    : rgba(255, 255, 255, 0.15);
  height        : 100%;
  opacity       : 0;
  pointer-events: none;
  transition    : .5s ease;
}

nav ul:hover .gls__nav__underline.gls__nav__underline--hover {
  opacity: 100%;
}

nav button {
  background: none;
  border    : none;
  box-sizing: border-box;
  color     : inherit;
  cursor    : pointer;
  margin    : 0 .5rem;
  outline   : none;
  padding   : .5rem 1rem;
  transition: .5s ease;
  font-size: clamp(12px, 1rem, 16px);
  font-weight: 300;
  opacity: .5;
}

nav button.active {
  opacity: 1;
}

nav.gls__nav.intro button {
  color: #101920;
}

nav.gls__nav.intro .gls__nav__underline.gls__nav__underline--active {
  background: #101920;
}

.gls__nav-backing {
  width: 30vw;
  height: 70px;
  position: fixed;
  top: 1rem;
  z-index: 10;
  margin: 0 auto;
  border-radius: .5rem;
}

.gls__nav-backing__holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  clip: rect(0, auto, auto, 0);
  clip-path: inset(0 0 0 0);
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.gls__section.gls__section--landing .gls__nav-backing {
  background: #819f87;
}

.gls__section.gls__section--work .gls__nav-backing {
  background: #688197;
}

.gls__section.gls__section--landing .gls__nav-contact {
  background: #a2aaad;
}



.gls__section.gls__section--tutorials .gls__nav-backing {
  background: #63666b;
}

