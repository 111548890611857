.gls__gallery {
  align-items    : center;
  box-sizing     : border-box;
  display        : flex;
  flex           : 2 1;
  flex-wrap      : wrap;
  justify-content: center;
  max-height     : 100%;
  max-width      : 100%;
  min-height     : 100%;
  min-width      : 100%;
  padding        : 1rem;
  position       : relative;
}

.gls__gallery-stack-ref {
  aspect-ratio  : 9 / 16;
  bottom        : 5%;
  box-sizing    : border-box;
  left          : 0;
  pointer-events: none;
  position      : absolute;
  visibility    : hidden;
  width         : 200px;
}

.gls__gallery-hero-ref {
  aspect-ratio  : 9 / 16;
  left          : 0;
  margin-left   : 2rem;
  pointer-events: none;
  position      : absolute;
  top           : 50%;
  transform     : translateY(-50%);
  visibility    : hidden;
  width         : 200px;
}

.gls__gallery-body {
    border-radius : 0;
    box-sizing    : border-box;
    height        : 100%;
    pointer-events: none;
    position      : absolute;
    /* right         : -100%; */
    right         : 0;
    top           : 0;
    transition    : .5s ease;
    z-index: 0;
  opacity: 0;

}

.gls__gallery-body.open {
  pointer-events: auto;
  opacity: 1;
  /* right         : 0; */
}

/* Accolades */

.gls__gallery__accolade {
  bottom    : 10%;
  left      : -2rem;
  max-height: 40%;
  max-width : 80%;
  position  : absolute;
  transition: .5s ease;
}

/* Cards */

.gls__gallery__card {
  aspect-ratio: 9 / 16;
  box-sizing  : border-box;
  cursor      : pointer;
  position    : absolute;
  transition  : width 1s ease, top 1s ease, left 1s ease, transform 1s ease;
}

.gls__gallery__card.gls__gallery__card--mobile {
  position    : relative;
}

.gls__gallery__card .gls__gallery__thumb {
  border-radius: .5rem;
  box-sizing   : border-box;
  max-width    : 100%;
  min-width    : 100%;
  object-fit   : cover;
  transition   : transform .5s;
}

.gls__gallery__card > div {
  transition  : .5s ease;
}

/* Nulls */

.gls__gallery-thumb-holder {
  text-align: center;
}

.gls__gallery__null {
  aspect-ratio  : 9 / 16;
  box-sizing    : border-box;
  display       : inline-block;
  pointer-events: none;
  position      : relative;
  visibility    : hidden;
  border: 1px solid red;
}

/* Vertical */

.gls__carousel.vertical .gls__project-nav button {
  transform: rotate(90deg);
}

.gls__carousel.vertical .gls__swiper-holder .swiper-slide:first-of-type {
  max-width: unset;
}

.gls__carousel.vertical .swiper-slide {
  min-height: unset;
}

.gls__carousel.vertical .gls__swiper-holder .swiper-slide {
  max-width: 100%;
}

.gls__carousel.vertical .swiper-slide:last-of-type {
  margin-right: unset;
}

.gls__carousel.vertical .gls__fade-mask {
  height    : 0.5rem;
  width     : 100%;
  background: linear-gradient(180deg, #688197, transparent);
  top       : 80px;
}

.gls__carousel.vertical .gls__swiper-holder {
  padding-bottom: 0;
}

.gls__carousel.vertical .gls__carousel-item {
  min-width: 100%;
}

.gls__carousel.vertical .gls__project-copy-block > div {
  max-width: unset;
}

.gls__carousel.vertical .swiper-wrapper {
    max-width: calc(100% - 1.5rem);
}

.gls__gallery__card.gls__gallery__card--mobile {
  margin: 4rem 0px;
}

.gls__gallery.gls__gallery--mobile .gls__project-copy-block p {
  display: none;
}

