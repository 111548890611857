.gls__about {
  --padding: 1.5rem;


  background    : white;
  border-radius : 0.5rem;
  box-shadow    : rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  box-sizing    : border-box;
  color         : #101920;
  display       : flex;
  flex-direction: column;
  font-size     : clamp(10px, 4vw, 18px);
  gap           : 1rem;
  height        : clamp(300px, calc(100vh - 160px), 700px);
  margin-top    : 100px;
  max-height    : 80dvh;
  max-width     : 90vw;
  padding       : var(--padding) var(--padding) calc(var(--padding) * 1) var(--padding);
  position      : relative;
  width         : 600px;
}

.gls__scroll-anim.gls__lander {
  bottom        : -20%;
  left          : -70%;
  pointer-events: none;
  position      : absolute;
  z-index       : 1;
}


.gls__about__header {
  align-items    : flex-end;
  box-sizing     : border-box;
  color          : #101920;
  display        : flex;
  gap            : var(--padding);
  justify-content: space-around;
      /* padding        : 1rem; */
}

.gls__about__header-image {
  align-items: flex-end;
      /* max-width      : 1px; */
  display        : flex;
  justify-content: center;
  max-height     : 1px;
  width          : clamp(80px, 20vw, 140px);
}

.gls__about__header-image img {
  border       : 4px solid #ffffff;
  border-radius: 1rem;
  box-shadow   : rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  max-width : 120px;
  object-fit: scale-down;
  width     : 100%;
}

.gls__about h1 {
  font-size : 2.62em;
  margin    : 0;
  text-align: center;
}

.gls__about h2 {
  font-size  : 1.62em;
  font-weight: 500;
  line-height: 1;
  margin     : 0;
  margin-top : 0.5rem;
  text-align : center;
}

.gls__about h3 {
  font-size  : 1.62em;
  font-weight: 300;
  line-height: 1;
  margin     : 0 0 .75rem;
}

.gls__about h4 {
  font-size  : 1em;
  font-weight: 300;
}

.gls__links {
  display        : flex;
  gap            : 2rem;
  justify-content: center;
  padding        : 0 2rem;
}

.gls__links i {
  font-size: 1aem;
}

.gls__links>* {
  align-items  : center;
  background   : #e6ece7;
  border-radius: 0.5rem;
  display      : flex;
      /* flex          : 2; */
  flex-direction: column;
  gap           : 0.2rem;
  padding       : 1rem;
  text-align    : center;
      /* width         : 100%; */
}

.gls__links span {

  display    : none;
  font-size  : 1em;
  font-weight: 300;
  white-space: nowrap;
}

.gls__about__tab-group>ul {
  align-items    : center;
  box-sizing     : border-box;
  display        : flex;
  gap            : 1rem;
  justify-content: space-around;
  list-style     : none;
  margin         : 0 auto;
  padding        : 1rem 1rem 2rem 1rem;
  width          : 100%;
}

.gls__about__tab-group>ul li {
  background: #ffffff;
  border    : 2px solid transparent;



  opacity: .5;
  padding: .25rem;

}

.gls__about__tab-group>ul li.active {
  background: #e6ece7;
  border    : 2px solid transparent;
  opacity   : 1;
}

.gls__about p {
  font-size  : 1em;
  font-weight: 500;
      /* text-justify: inter-word;
  text-align : justify; */
  font-weight: 300;
  margin     : 0 0 1rem 0;
}

.gls__about__tab-group {
  padding: 0 0rem 1rem;
}

.gls__about__header .gls__icon-button {
      /* box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.15),
  0px 2px 5px rgba(0, 0, 0, 0.05) ; */
  margin: 0 0 2rem 0;
}

.gls__icon-button {
  background   : white;
  border       : none;
  border-radius: .5rem;
  box-shadow   : 0px 0px 0px rgba(17, 17, 26, 0.0), 0px 0px 0px rgba(17, 17, 26, 0.0);

  color         : #101920;
  cursor        : pointer;
  flex          : 2;
  font-size     : .8em;
  letter-spacing: 1.2px;
  outline       : none;
  overflow      : hidden;
  padding       : 0.5rem 0;
  position      : relative;
  text-align    : center;
      /* border: 1px solid white; */


  -webkit-text-decoration: none;
  text-decoration        : none;
  transition             : .5s;
  white-space            : nowrap;
}

.gls__icon-button:hover {
  background: #e6ece7;

  box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.15),
    0px 2px 5px rgba(0, 0, 0, 0.05);
}







.gls__about__tab-group {
      /* height: calc(100dvh - 700px); */
      /* border: 3px solid orange; */
      /* flex-grow: 1; */
      /* flex-shrink: 0; */
      /* max-height: 440px; */
  box-sizing    : border-box;
  display       : flex;
  flex          : 2;
  flex-direction: column;
      /* background: red; */
  overflow: hidden;
  position: relative;
}

.gls__about__tab-body {
  flex       : 2;
  flex-grow  : 1;
  flex-shrink: 0;
  overflow   : hidden;
      /* display: flex; */
      /* flex-direction: column; */
}

.gls__icon-button span {
  align-items: center;
  display    : inline-flex;
  height     : 100%;
  margin     : 0 .25rem;
  overflow   : hidden;
  transition : .5s;
}

.gls__icon-button span:first-of-type {
  justify-content: flex-end;
  width          : 1.4rem;
}

.gls__icon-button span:last-of-type {
  justify-content: flex-start;
  width          : 0;
}

.gls__icon-button:hover span:first-of-type {
  width: 0rem;
}

.gls__icon-button:hover span:last-of-type {
  width: 1.4rem;
}





.gls__about__tab-body {
      /* aspect-ratio: 1.4; */
  position: relative;


}

.gls__about-scroll {
  flex      : 2;
  margin    : 0 0 1rem 0;
  max-height: 100%;
  max-height: 100%;
  overflow  : auto;

  overflow        : auto;
  padding         : 0 1rem;
  scroll-snap-type: y mandatory;

}

.gls__about-scroll:after {
  bottom        : 0;
  box-shadow    : inset 0px -20px 20px -10px white;
  content       : "";
  left          : 0;
  pointer-events: none;
  position      : absolute;
  right         : 0;
  top           : 0;
  z-index       : 100;
}

.gls__pull-quote {
  align-items    : center;
  border-bottom  : 1px solid rgba(0, 0, 0, 0.05);
  box-sizing     : border-box;
  display        : flex;
  flex-direction : column;
  gap            : .25rem;
  justify-content: center;
      /* margin-bottom    : 2rem; */
  padding          : 2rem 0;
  scroll-snap-align: start;
}

.gls__pull-quote:last-of-type {

  border-bottom: none;
}

.gls__pull-quote:first-of-type {
  padding-top: 1rem;
}


.gls__pull-quote p {
  box-sizing : border-box;
  font-size  : 1em;
  font-weight: 300;
  line-height: 1.2;
  margin     : 0 .5em 1rem;
  text-align : center;
}

.gls__pull-quote h2 {
  font-size  : 1.62em;
  font-weight: 300;
  margin     : 0;
}

.gls__pull-quote h3 {
  font-size  : 1em;
  font-weight: 700;
  margin     : 0;
  text-align : center;
}

.gls__pull-quote h4 {
  font-size  : 1em;
  font-weight: 300;
  line-height: 1;
  margin     : 0;
  text-align : center;
}

.gls__pull-quote>div {
  align-items: center;
  display    : flex;
}

.gls__pull-quote>div i {
  display  : inline-block;
  font-size: .8em;
  padding  : 0 .5em;
}


.gls__skills-category ul {
  font-size  : 1em;
  font-weight: 300;
  margin     : 0 0 1rem 0;
}

.gls__skills-list {
  margin: 0;
}

.gls__skills-category {
  margin-bottom: 1rem;
}

    /* .gls__skills-category {
  scroll-snap-align: start;
} */


.gls__skills-category h3 {
  font-size  : 1.62em;
  line-height: 1;
  margin     : 0 0 0.2rem;
}





.gls__about__tab-body.fade-in {
  animation: fadeIn 0.3s ease forwards;
}

.gls__about__tab-body.fade-out {
  animation: fadeOut 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}





@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .gls__links {
        /* flex-wrap: wrap; */
    gap: .5rem;
  }

  .gls__links>* {
    flex : unset;
    width: unset;
  }
}


@media (max-width: 768px) {

  .gls__icon-button span {
    display: none;
  }

}



.gls__letter>div {
  align-items   : flex-end;
  display       : flex;
  flex-direction: column;
  text-align    : right;
  width         : 100%;
}

.gls__letter img {
  margin-bottom: -0.5rem;
  max-width    : 90px;
  object-fit   : scale-down;
}

.gls_letter p {
  box-sizing : border-box;
  font-size  : 1em;
  line-height: 1.2;
  margin     : 0 .5em 1rem;
}


    /* TEMP */

.gls__portfolio-body>h1 {
  position: fixed;
  z-index : 100;
}