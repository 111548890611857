.gls__rotate-screen {
  align-items    : center;
  background     : radial-gradient(#688197, #688197, #515f6b);
  bottom         : 0;
  box-sizing     : border-box;
  color          : white;
  display        : flex;
  flex-direction : column;
  height         : 100%;
  justify-content: space-around;
  left           : 0;
  padding        : 2rem;
  position       : fixed;
  right          : 0;
  top            : 0;
  width          : 100%;
  z-index        : 10000;
}

.gls__rotate-screen h3 {
  font-weight: 300;
}

.gls__rotate-screen>i {
  font-size: 5rem;
}